<template>

    <div class="edit-video"   :style="{ backgroundImage: this.scenedata.length > 0 ? `url(${scenedata[0].Image})` : 'None' }" >
        <!-- Your template code goes here -->
         

         <!-- :style="{ backgroundImage: `url(${scenedata[currentscene].Image})` }" -->
            <div class="videoeditor"  v-if="this.scenedata.length > 0" >
                <img class="sideimage" :src="scenedata[currentscene].Image" alt="Scene Image" width="80%"/>
                <div class="imageoverlay" v-if="this.imageloading"/>
                
                <div class="scenepreview">
                    <div class="loading-placeholder"></div>
                    <!-- <video ref="videoplayer" class="videoplayer" width="80%" controls> -->
                    
                    <!-- <source :src="videourl" type="video/mp4">
                    Your browser does not support the video tag.
                    </video> -->
                    
                </div>
                
                <Dialog v-model:visible= "showSaveProgress" ref="savediag" modal :closable="false">
                    <div class="p-d-flex p-ai-center p-jc-center">
                        <ProgressSpinner/>
                    </div>
                </Dialog>
                




                <div class="editform">
                
                    <SelectButton v-tooltip="'Select the scene/slide you want to change'" class= "selectbutton" @change="refreshVideo" v-model="this.selectedscene" :options= "getValidSceneCounts()"  />
                    <label for="caption">Title</label>
                        <InputText class="iptext" type="text" id="caption" name="caption" @change="changescene(currentscene,'title')"  v-model="scenedata[currentscene].SlideTitle"/>
                    <label for="caption"> {{this.currentscene == 0 ? "Video Title" : (this.currentscene == this.scenedata.length-1 ? "Closing Statement": "Key Point")}} </label>
                        <InputText v-if="this.currentscene !=0 && this.currentscene != this.scenedata.length-1" class="iptext" type="text" id="caption" name="caption" @change="changescene(currentscene,'keypoint')"  v-model="scenedata[currentscene].KeyPoint"/>
                        <InputText v-if="this.currentscene == 0" class="iptext" type="text" id="caption" name="caption" @change="changescene(currentscene,'PresentationTitle')"  v-model="scenedata[currentscene].PresentationTitle"/>
                        <InputText v-if="this.currentscene > 0 && this.currentscene==this.scenedata.length-1" class="iptext" type="text" id="caption" name="caption" @change="changescene(currentscene,'ClosingStatement')"  v-model="scenedata[currentscene].ClosingStatement"/>

                        <label for="voiceover">Voice Over</label>
                        <InputText class="iptext" type="text" id="voiceover" name="voiceover" @change="changescene(currentscene,'voiceover')" v-tooltip="'Change this to change the audio voice track for the scene'" v-model="scenedata[currentscene].VoiceOver"/>
                        <label for="scene">Image Prompt</label>
                        <div class="scenediv">
                        <InputText class="iptextscene" v-tooltip="'Change this prompt  to change the image on the left'" type="text" id="scene" name="scene" @change="changescene(currentscene,'scene')"  v-model="scenedata[currentscene].Scene"/>
                        <img class="imageicon" v-if="docimages.length > 0" :src="require('@/assets/refreshicon.png')" alt="Refresh Image" width="100%" v-tooltip="'Generate Another AI image for prompt'" @click="changescene(currentscene,'imagerefresh')"/>

                        <img class="imageicon" v-if="docimages.length > 0" :src="require('@/assets/imageicon.png')" alt="Document Image" width="100%" v-tooltip="'Upload your own image or use an image from document'"  @click="this.$refs.op.toggle($event)"/>
                        
                        
                    
                        </div>
                        <OverlayPanel ref="op" :style="{maxWidth: '40vw'}">
                            <!-- <div v-for="img,index in this.docimages" :key="index">
                                <img :src="img" alt="Document Image" width="100%"/>
                            </div> -->
                            
                            <Galleria :value="this.docimages"   :responsiveOptions="responsiveOptions" :numVisible="5"   showIndicators autoplay containerStyle="max-width: 100px , max-height: 100px , ">
                                <template #thumbnail="slotProps">

        <img :src="slotProps.item" :alt="slotProps"  :style="{height: '100px',width: '100px',margin:'10px'}" @click="changescene(currentscene,'image',slotProps.item)" />
    </template>
                            </Galleria>
                        </OverlayPanel>
                        <Button class = "nextbutton" :disabled = "false"  @click="savevideo()" type="submit" v-tooltip = "'Save your changes and regenerate the video'">Save and Regenerate Video</button>
                </div>
                
            </div>
    </div>

    


    
</template>

<script>
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import SelectButton from 'primevue/selectbutton';
import * as amplitude from '@amplitude/analytics-browser';
import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';
import OverlayPanel from 'primevue/overlaypanel';
import Galleria from 'primevue/galleria';



export default {
    name: 'EditVideo',
    emits:["videosaved"],
    data(){
        return{
            scenedata: [],
            videodata: {},
            videolist: [],
            videourl: "",
            currentscene: 0,
            selectedscene: "Scene 1",
            options:[0,1,2,3,4],
            playheadposition: 0,
            showSaveProgress: false,
            docimages:["https://sdgenvideos.s3.us-west-1.amazonaws.com/images/uploadicon.png","https://sdgenvideos.s3.us-west-1.amazonaws.com/images/loading.gif"],
            imageloading: false

        }
        },
    props: {
        videoId: {
            type: String,
            required: true
        }
    },
    components: {
    InputText, Button,SelectButton,Dialog,ProgressSpinner,OverlayPanel,Galleria
  },

    methods: {

    getValidSceneIndices() {
    return this.scenedata
      .map((scene, index) => (scene.renderedVideo !== undefined ? index : -1))
      .filter(index => index !== -1);
  },

  getValidSceneCounts() {
        const count = this.getValidSceneIndices().length;
        return Array.from({ length: count }, (_, i) => `${i + 1}`);
  },

  savevideo(){
    // console.log (this.scenedata)
    var payload = {
                "scenedata": this.scenedata,
                "templateid": this.videodata.templateid,
                "userid": this.videodata.userid,
                "jobid": this.videodata.jobid,
                "env": "prod",
                "documenturl": this.videodata.documenturl,
                "voice": this.videodata.voice,
                "bgmusic": this.videodata.musicurl,
                "origvideoid": this.videoId,

            }
            console.log("Payload",JSON.stringify(payload))
            fetch("https://utu7suvdrtyfsiixzpa6juzbtq0umaks.lambda-url.us-west-1.on.aws/",{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            }).then((response) => response.json())
            .then((data) => {
                this.showSaveProgress=false
                console.log('Success:', data);
                this.$emit("videosaved",data.video)
            })
            .catch((error) => {
                this.showSaveProgress=false
                console.error('Error:', error);
            });
            this.showSaveProgress=true
            
            

        


  },

  changescene(index,field,val=null){
    console.log("changing scene",index,field)
    amplitude.track('Scene Data Changed', { field: field, scene: index });  
    
    if (field=="voiceover"){
        this.scenedata[index].audio = null
    }
    else if (field=="scene"){
        this.scenedata[index].Image=null
    }
    else if (field=="image"){
        if (val == "https://sdgenvideos.s3.us-west-1.amazonaws.com/images/loading.gif" ){
            return
        }

        if (val=="https://sdgenvideos.s3.us-west-1.amazonaws.com/images/uploadicon.png"){
            console.log("upload image")
            // show file upload dialog
            var fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = 'image/*';
            fileInput.click();
            fileInput.onchange = (e) => {
                var file = e.target.files[0];
                console.log("File",file)
                //convert image to base64
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    const base64String = reader.result;
                    console.log("Base64 String", base64String);
                    payload={"image":base64String}
                    this.imageloading=true
                    const uploadurl="https://byx64c7re4xufi2iuvsjwi2jme0fexpc.lambda-url.us-west-1.on.aws/"
                    fetch(uploadurl,{
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(payload)
                    }).then((response) => {return response.json()}).then((data) => {
                        console.log("Image uploaded",data)
                        
                        if (!this.docimages.includes(this.scenedata[index].Image)){
                        this.docimages.push(this.scenedata[index].Image)
                        }
                        this.scenedata[index].Image=data.imageURL
                        if (!this.docimages.includes(data.imageURL)){
                        this.docimages.push(data.imageURL)
                        }
                        this.imageloading=false
                    })
                    this.scenedata[index].renderedVideo = null;
                };
                reader.onerror = (error) => {
                    console.error('Error: ', error);
                };
            };
        }
        else {



        if (!this.docimages.includes(this.scenedata[index].Image)){
        this.docimages.push(this.scenedata[index].Image)
    }
        
        this.scenedata[index].Image=val
    
        this.scenedata[index].renderedVideo=null
}
    }
    else if (field=="imagerefresh"){
        const imagegenurl="https://b456hlqj776t7nynskyzzwsstm0mpsjg.lambda-url.us-west-1.on.aws/"
        console.log("Refreshing image",this.scenedata[index])
        if (!this.docimages.includes(this.scenedata[index].Image) && this.scenedata[index].Image !== null){
            this.docimages.push(this.scenedata[index].Image)
        }
        this.imageloading=true
        var payload={"prompt":this.scenedata[index].Scene}
        console.log("Payload",JSON.stringify(payload))
        fetch(imagegenurl,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        }).then((response) => {return response.json()}).then((data) => {
            this.scenedata[index].Image=data.image
            this.imageloading=false
        })
    }
    this.scenedata[index].renderedVideo=null
  },

  handleKeydown(event) {
      const validScenes = this.getValidSceneCounts();
      const currentIndex = validScenes.indexOf(this.selectedscene);

      if (event.key == 'ArrowRight' && currentIndex < validScenes.length - 1) {
        this.selectedscene = validScenes[currentIndex + 1];
        this.refreshVideo();
      } else if (event.key == 'ArrowLeft' && currentIndex > 0) {
        this.selectedscene = validScenes[currentIndex - 1];
        this.refreshVideo();
      }
    },

     getVideoDuration(url) {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.src = url;
    video.preload = 'metadata';

    video.onloadedmetadata = function() {
      resolve(video.duration);
    };

    video.onerror = function() {
      reject('Error loading video');
    };
  });
},

fetchdocimages(docdata){
    this.docimages=["https://sdgenvideos.s3.us-west-1.amazonaws.com/images/uploadicon.png","https://sdgenvideos.s3.us-west-1.amazonaws.com/images/loading.gif"]

    //convert json string to json object
    console.log("inside fetchdocimages",docdata);
    console.log(docdata);
    // docdata = JSON.parse(docdata);

        
    docdata.forEach(item => {
        if (item.images !== undefined && item.images !== null && item.images.length > 0) {
            console.log("Fetching document images", item.images);
            item.images.forEach(image => {
                console.log("Fetching document image", image);
                if (!this.docimages.includes(image.url)) {
                    this.docimages.push(image.url);
                }
                
            });
        }
    });
    this.docimages= this.docimages.filter(item => item !== "https://sdgenvideos.s3.us-west-1.amazonaws.com/images/loading.gif");

},

       async refreshVideo() {
             
            console.log("in refresh video")
            console.log (this.selectedscene,"selected scene")
            if ( this.selectedscene === undefined || this.selectedscene === null || this.selectedscene === ""){
                return
            }
            console.log("curent scene",this.currentscene)
            
            var currentdur=0
            
            
            console.log(currentdur,"current duration")
            this.currentscene = this.getValidSceneIndices()[parseInt(this.selectedscene.replace("Scene ", "")) - 1];
            if (this.scenedata[this.currentscene].startpos !== undefined){
                currentdur=parseInt(this.scenedata[this.currentscene].startpos)+2
            }
                console.log ("start pos",currentdur)
            console.log(this.currentscene,"current scene")
            console.log(this.scenedata[this.currentscene])
            //var videoPlayer =this.$refs.videoplayer
            //videoPlayer.load();
            //console.log("Moving from",videoPlayer.currentTime,"to",currentdur)
            //videoPlayer.onloadeddata = () => {
              //  videoPlayer.currentTime =  currentdur; // Set the desired time
                // videoPlayer.play();
            //};
            
    //   this.$refs.videoplayer.load();
    //   this.$refs.videoplayer.play();
    },
        async getSceneData(){



        var payload={
                "videoid": this.videoId
        }   

            const response=await fetch("https://txevtuio4jeapl6ocpo6w23mcq0ixwkn.lambda-url.us-west-1.on.aws/",{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })
            if(response.ok){
                this.videodata=await response.json()
                this.scenedata=this.videodata.scenedata
                this.videourl=this.videodata.videourl
                
                console.log("Scene data",this.scenedata)
                console.log("Fetching document image",this.videodata.documenturl)
                this.docimages.push("https://sdgenvideos.s3.us-west-1.amazonaws.com/images/loading.gif")
                if (this.videodata.documenturl !== undefined && this.videodata.documenturl !== null && this.videodata.documenturl !== ""){
                if (!this.docimages.includes("https://sdgenvideos.s3.us-west-1.amazonaws.com/images/loading.gif")) {
                    console.log("pushing loading gif")
                    this.docimages.push("https://sdgenvideos.s3.us-west-1.amazonaws.com/images/loading.gif")}
                const documentimageurl="https://2oo27y36xibh36oqycln2zpqru0eezfr.lambda-url.us-west-1.on.aws/"
                payload={"fileurl":this.videodata.documenturl,"extract_images":true}
                fetch(documentimageurl,{
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                }).then((response) => {return response.json()}).then((data) => {
                    this.fetchdocimages(data)
                })

                }
                else{
                    console.log("No document image found")
                    this.docimages= this.docimages.filter(item => item !== "https://sdgenvideos.s3.us-west-1.amazonaws.com/images/loading.gif");

                }
            }
            else{
                console.log("Error in fetching scene data")
            }

            

        },
        updateBackgroundImage(url) {
            this.backgroundImageUrl = url;
        }
    },
    computed: {
        // Your computed properties go here
    },
    mounted() {
         console.log("Video Id for editing",this.videoId)
         amplitude.track('Edit Video Page Viewed', { videoId: this.videoId });
         this.getSceneData()
        //  window.addEventListener('keydown', this.handleKeydown);





    },

  beforeUnmount() {
    // window.removeEventListener('keydown', this.handleKeydown);
  },
};
</script>



<style scoped>
.edit-video {
    position: relative;
    overflow: hidden;
}

.edit-video::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    background-size: cover;
    background-position: center;
    opacity: 0.6; /* Set the opacity to 70% */
    z-index: 1; /* Ensure the pseudo-element is behind the content */
}

.videoeditor{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height:100%;
    background-size: cover; /* Optional: to cover the entire div */
    background-position: center; /* Optional: to center the image */
    z-index: 2;
}
.scenepreview{
    margin-right: 10px;
    width:30%;
    align-self: center;
    align-items: flex-start;
    justify-content: flex-start;
}
.editform{
    display: flex;
    width:70%;
    flex-direction: column;
    align-items: left;
    justify-content: space-evenly;
    margin-left: 10px;
    row-gap: 10px;
    height: 100%;
    z-index: 2;
    
}

button.nextbutton {
    margin-top: 2vw;
    margin-bottom: 10px;
    align-self: center;
    /* justify-self: ; */
    background: #F3610F;
    color: #FFF;
    border: none;
    padding: 7px 25px;
    border-radius: 10px;
    font-weight: 500;
    order: 2;
}

.videoplayer{
    
    border: 1px solid #f4efef;
    border-radius: 2px;
}

.sideimage{

    position: absolute;
    top:0px;
    border-radius: 2px;
    object-fit: cover;
    height: 100%;
    width: 30%;

}

.imageoverlay{

position: absolute;
top:0px;
border-radius: 2px;
height: 100%;
width: 30%;
background-color: #000;
opacity: 0.6;

}

.selectbutton{
    display:flex;
    margin-bottom: 20px;
    height: 30px;
    width:70%;
    justify-self: unset;
    align-self: center;
}

.iptext{
    width: 90%;
    height: 3em;
    border: 1px solid #f4efef;
    border-radius: 15px;
    padding: 5px;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    background-color: #f4efef;
    box-shadow: #000 0px 0px 2px 2px;


}


.iptextscene{
    width: 90%;
    height: 3em;
    border: 1px solid #f4efef;
    border-radius: 15px;
    padding: 5px;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    background-color: #f4efef;
    box-shadow: #000 0px 0px 2px 2px;
}

.imageicon{
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right:10px;
    cursor: pointer;
}

.imageicon:hover{
    transform: scale(1.2);
}

.scenediv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}


.p-dialog{
    background-color: #f4efef;

}

@media(max-width:768px) {

    .videoeditor{
        flex-direction: column;
    }
    .scenepreview{
        width:100%;
    }
    .editform{
        width:100%;
    }
}


</style>