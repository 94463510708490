<template>
    <div class="container">



    </div>


</template>

<script>
import * as amplitude from '@amplitude/analytics-browser';
import { saveAs } from 'file-saver';
import { event } from 'vue-gtag';

export default {
    name: 'ProcessPurchase',
    components: {},
    emits: [],
    head() {
        return {
            utm_content: ""
        }
    },
    data() {
        return {

        }
    },

    methods: {
        generateUUID() {
            return 'xxxxxxxx'.replace(/[x]/g, function (c) {
                console.log("c is ", c)
                var r = Math.random() * 16 | 0;
                return r.toString(16);
            });
        },
        async downloadImage(imageUrl) {


            console.log("Image url is ", imageUrl)
            try {
                const response = await fetch(imageUrl, {
                    headers: {
                        'Cache-Control': 'no-cache'
                    }
                });
                const blob = await response.blob();
                console.log('Blob created successfully');
                saveAs(blob, 'AdGenie_' + this.generateUUID() + ".jpg");
                console.log('Image downloaded successfully');
                amplitude.track('DownloadImageSuccess', { "imageUrl": imageUrl, "productid": this.productId });
                window.location.href = "/";

            } catch (error) {

                console.error('There was an error downloading the image', error)
                window.open(imageUrl);
                amplitude.track('DownloadCORSError', { "imageUrl": imageUrl, "productid": this.productId });
            }

        },
        async downloadVideo(videoURL) {


            try {
                const response = await fetch(videoURL, {
                    headers: {
                        'Cache-Control': 'no-cache'
                    }
                });
                const blob = await response.blob();
                console.log('Blob created successfully');
                saveAs(blob, 'AdGenie_' + this.generateUUID() + ".mp4");
                console.log('Video downloaded successfully');
                amplitude.track('DownloadVideoSuccess', { "imageUrl": videoURL, "productid": this.productId });
                window.location.href = "/";

            } catch (error) {

                console.error('There was an error downloading the image', error)
                window.open(videoURL);
                amplitude.track('DownloadCORSError', { "imageUrl": videoURL, "productid": this.productId });
            }

        }



    },

    created() {
        console.log("created in Process Payment", this.utm_content);
        amplitude.track('Process Payment ', { "utm_content": this.utm_content })
    },
    mounted() {
        // Accessing query parameters


        // Example: Accessing a specific query parameter named 'id'
        const utm_content = this.$route.query.utm_content;
        console.log('utm_content passed from query:', utm_content);
        // this.utm_content=btoa(utm_content);
        console.log('utm_content passed from query:', this.utm_content);
        var adid = utm_content.split("_")[1];
        var extcode = utm_content.split("_")[2];
        var ext = ".jpg"
        if (extcode == "2") {
            ext = ".png"
        }
        var imageurl = "https://adgenimages.s3-us-west-1.amazonaws.com/genimages/" + adid + ext;

        if (extcode == "3"){
            ext=".mp4"
            imageurl = "https://sdgenvideos.s3.us-west-1.amazonaws.com/videos/" + adid + ext;
            this.downloadVideo(imageurl);
        }
        else {
        this.downloadImage(imageurl);
        event('Puchase success', { "content":imageurl, "userid": this.adgenieuserid })
    }
    },
}

</script>
<style scoped>
.adimg {
    max-width: 100%;
    height: auto;
    -webkit-touch-callout: none;
    justify-content: center;
}

.adfooter {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.logo {
    width: 50px;
    height: auto;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: top;
    height: 100vh;
    /* Adjust as needed */
}

.poweredby {
    margin-top: 14px;
    color: beige;
}

@media screen and (max-width: 1000px) {}

@media screen and (max-width: 768px) {}
</style>