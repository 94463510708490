<template>
  <div class="container">

    <template>
      <div class="card flex justify-content-center">
        <Sidebar v-model:visible="showChapters" header="Chapters" position="right">
          <div class="chapterList">
            <div v-for="scenes in scenedata" :key="scenes.SlideTitle">
              <div class="chapter" @click="changeScene(scenes)">
                <a>{{ scenes.SlideTitle }}</a>

              </div>
            </div>
          </div>

        </Sidebar>
        <Button icon="pi pi-arrow-right" @click="visible = true" />
      </div>
    </template>
    

    <div class="video-container">
      <video ref="videoplayer" :class="['videoplayer', { 'rainbow-spin': isRainbowSpin }]" width="80%" controls
        @contextmenu.prevent controlsList="nodownload noplaybackrate nopictureinpicture "
        @keydown="handleKeydown($event)" 
        >
        <source :src="`
https://sdgenvideos.s3.us-west-1.amazonaws.com/videos/${this.videoid}.mp4`" type="video/mp4">

        Your browser does not support the video tag.
      </video>
      <img ref="answerimage" :class="['imageoverlay', { visible: this.showanswerimage, hidden: !this.showanswerimage }]"
        :src="this.answerimage" alt="answer" @load="onAnswerImageLoad" />
        <div class="searchgroup" v-if="this.videoid.includes('SPTMP-36S-DOC-2')" >
    <InputText class="queryinput" placeholder="Ask questions about this topic..." type="text"
    :disabled="qinprogress" @keyup.enter="this.queryVideo()" v-model="querytext" variant="filled" />
    <Button icon="pi pi-search" class="searchbtn" @click="this.queryVideo()"  />
    <!-- <img ref="chapterbtn" title="Chapters" class="nonquerybutton" src="../assets/chaptericon.png" @click="this.showChapters = true" /> -->
        </div>
    </div>
    <Message :style='{ "margin-top": "50px" }' :life=3000 :closable="true" @close="this.warnMessage = '';"
      v-if="this.warnMessage.length > 0" severity="warn">{{
        this.warnMessage }}

    </Message>

    <div class="adfooter">

      <!-- <div class="actionspanel">
        <img ref="aibtn" title="Present this video" class="nonquerybutton" src="../assets/presenticon.png"
          @click="presentVideo($event)" />
        <img ref="aibtn" title="Ask Questions about this video" class="querybutton" src="../assets/queryvideo.png"
          @click="clickpopquery($event)" />
        <img ref="chapterbtn" title="Chapters" class="nonquerybutton" src="../assets/chaptericon.png"
          @click="this.showChapters = true" />
      </div> -->
      <div>
         <p class="invitetext"> Create your own interactive video from any document or text with <a  href="https://www.fictic.ai"> Fictic AI </a> </p> 

      </div>

      <OverlayPanel ref="popquery" class="queryop">
        <div>
          <InputText :style="{ width: '30em' }" placeholder="Ask anything about this video topic..." type="text"
            :disabled="qinprogress" @keyup.enter="this.queryVideo()" v-model="querytext" variant="filled" />
          <Button class="askbutton" :disabled="qinprogress" label="Ask" @click="this.queryVideo()" rounded />
        </div>
      </OverlayPanel>

        
        <img class="embedbtn" src="../assets/embed.png" alt="logo" @click="this.copyembedlink()" />
      <p class="">Embed this video in your page</p>


      <a href="https://www.fictic.ai">
        <img class="logo" src="../assets/ficticlogo.png" alt="logo" /></a>
    </div>
  </div>



  <Dialog v-model:visible="this.showVideoEditor" modal header="Edit Video" :showHeader="true" :style="{ width: '70vw', margin:'0px', padding:'0px', border:'1px solid black' , boxShadow: '0 0 10px 10px rgba(87, 85, 85, 0.5)'}"
    :closeOnEscape="false" :draggable="false" position="Left" :keepInViewPort="false">
    <!-- <template #header>
      <div class="dialogheader">
        <span class="font-bold whitespace-nowrap"> Edit Video </span>
      </div>
    </template> -->

    <EditVideo :videoId="this.editVideoId" @videosaved="loadSavedVideo" />

  </Dialog>


</template>

<script>
import * as amplitude from '@amplitude/analytics-browser';
import EditVideo from './EditVideo.vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import OverlayPanel from 'primevue/overlaypanel';
import InputText from 'primevue/inputtext';
import Message from 'primevue/message';

import Sidebar from 'primevue/sidebar';
// import InputGroup from 'primevue/inputgroup';
// import InputGroupAddon from 'primevue/inputgroupaddon';








export default {
  name: 'ShowVidbyId',
  components: { EditVideo, Dialog, Button, OverlayPanel, InputText, Sidebar, Message },
  emits: [],
  props: ["videoid"],


  data() {
    return {
      title: this.videoid,
      showVideoEditor: false,
      querytext: '',
      documenturl: "",
      qinprogress: false,
      isRainbowSpin: false,
      currentoffset: 0,
      showChapters: true,
      scenedata: [],
      videodata: {},
      answeraudio: null,
      visible: false,
      answerimage: "https://sdgenvideos.s3.us-west-1.amazonaws.com/images/invisibleimage.png",
      showanswerimage: false,
      warnMessage: "",
    }
  },
  methods: {
    redirectToUrl() {
      window.location.href = 'https://app.fictic.ai';
    },
    clickpopquery(event) {
      console.log("clickpopquery", event)
      console.log(this.$refs.popquery)
      this.$refs.popquery.toggle(event);
    },
    toggleRainbowSpin() {
      this.isRainbowSpin = !this.isRainbowSpin;
    },

    changeScene(scene) {
      console.log("change scene", scene, scene.startpos + 1)
      this.$refs.videoplayer.currentTime = parseInt(scene.startpos) + 1;
      console.log("current time is ", this.$refs.videoplayer.currentTime)
    },

    queryVideo() {
      this.answeraudio = null
      amplitude.track('VideoQuestionAsked', { video: this.videoid, question: this.querytext });

      console.log("queryVideo", this.querytext)
      this.qinprogress = true
      this.isRainbowSpin = true
      this.$refs.popquery.hide();
      const videoPlayer = this.$refs.videoplayer;
      if (!videoPlayer.paused) {
        videoPlayer.pause();
        this.currentoffset = videoPlayer.currentTime;


      }
      console.log(this.scenedata)
      this.answerimage = this.scenedata[0].Image
      this.startAnimation();


      fetch('https://qsj5junm5upefdmznlhqweuhey0rbjkr.lambda-url.us-west-1.on.aws/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ videoid: this.videoid, query: this.querytext }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('Success:', data);
          this.qinprogress = false

          this.processqueryoutput(data)

        })
        .catch((error) => {
          this.$refs.popquery.hide();
          this.qinprogress = false
          console.error('Error:', error);
        });
    },
    loadSavedVideo(savedvideo) {
      console.log("loadSavedVideo", savedvideo)
      this.showVideoEditor = false;
      if (savedvideo !== undefined) {

        amplitude.track('EditedVideoGenerated', { video: savedvideo });


        // refresh page with new video
        const timestamp = new Date().getTime();
        const newUrl = `${savedvideo}?t=${timestamp}`;
        console.log("new url is ", newUrl)
        this.$refs.videoplayer.src = newUrl
      }


    },
    copyembedlink() {
      console.log("clickembedlink")
      var embedlink='<iframe  frameborder="0" allow="fullscreen" src="https://www.fictic.ai/share/' + this.videoid +  '" width="540px" style="aspect-ratio: 16 / 11;  height: auto; resize: horizontal; overflow: auto;"></iframe>'
      amplitude.track('EmbedLinkClicked', { video: this.videoid, embedlink: embedlink });
      this.warnMessage = "Embed link copied to clipboard"
      navigator.clipboard.writeText(embedlink).then(() => {
        console.log('Embed link copied to clipboard');
       
      }).catch(err => {
        console.error('Failed to copy: ', err);
      });
      this.warnMessage = "Embed link copied to clipboard"
      setTimeout(() => {
        this.warnMessage = "";
      }, 3000);
    },

    onAnswerImageLoad() {
      console.log("onAnswerImageLoad")
      if (this.answeraudio != null) {


        var audio = new Audio("data:audio/wav;base64," + this.answeraudio);
        // introduce a 1 second delay
        audio.play();

        audio.addEventListener('ended', () => {
          this.isRainbowSpin = false;
          // this.showanswerimage = false;
          // this.answerimage = "";
          this.finishAnimation();
          if (this.currentoffset > 0) {

            const videoplayer = this.$refs.videoplayer;
            videoplayer.currentTime = this.currentoffset;
            // // videoplayer.focus();
            videoplayer.play();
          }
        });
      }
    },



    processqueryoutput(data) {
      console.log("processqueryoutput", data)
      this.documenturl = data.documenturl
      console.log(data.answer)
      if (data.image != "") {
        console.log("setting image", data.image)
        this.answerimage = data.image;
        this.startAnimation();
      }
      else {
        this.showanswerimage = false;
        console.log("no image")
        if (data.startpos) {
          //convert data.startpos to seconds
          var timetoset = parseInt(data.startpos) + 2
          console.log("setting time to ", timetoset)
          this.$refs.videoplayer.currentTime = timetoset;
          console.log("current time is ", this.$refs.videoplayer.currentTime)
        }
      }

      this.answeraudio = data.audio


      // Decode the base64 string from data.audio and play it



    },
    async getSceneData() {
      var payload = {
        "videoid": this.videoid
      }

      const response = await fetch("https://txevtuio4jeapl6ocpo6w23mcq0ixwkn.lambda-url.us-west-1.on.aws/", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      })
      if (response.ok) {
        this.videodata = await response.json()
        this.scenedata = this.videodata.scenedata
        this.videourl = this.videodata.videourl

        console.log("Scene data", this.scenedata)
      }
      else {
        console.log("Error in fetching scene data")
      }



    },



    async presentVideo(event) {
      try {
        console.log("presentVideo", event)
        const videoElement = this.$refs.videoplayer;
        if (videoElement) {
          // Request Picture-in-Picture mode
          await videoElement.requestPictureInPicture();
          videoElement.play();
          // Request fullscreen mode
          if (videoElement.requestFullscreen) {
            videoElement.requestFullscreen();
          } else if (videoElement.mozRequestFullScreen) { /* Firefox */
            videoElement.mozRequestFullScreen();
          } else if (videoElement.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            videoElement.webkitRequestFullscreen();
          } else if (videoElement.msRequestFullscreen) { /* IE/Edge */
            videoElement.msRequestFullscreen();
          }
        }
      } catch (error) {
        console.error("Error presenting video in PiP and fullscreen:", error);
      }
    },
    startAnimation() {
      this.showanswerimage = false; // Reset the state to trigger the animation
      this.$nextTick(() => {
        this.showanswerimage = true; // Set the state to trigger the animation
      });
    },
    finishAnimation() {
      this.showanswerimage = true; // Reset the state to trigger the animation
      this.$nextTick(() => {
        this.showanswerimage = false; // Set the state to trigger the animation
      });
    },
    handleKeydown(event) {
      if (event.ctrlKey && event.key == 'g') {
        console.log("you pressed ", event.ctrlKey, event.key)
        // var videoid=videoimage.split("/").pop().split(".")[0]
        console.log("video id is ", this.videoid)
        this.editVideoId = this.videoid
        this.showVideoEditor = true;
      }
      if (event.key == 'ArrowLeft') {
        console.log("you pressed ", event.ctrlKey, event.key)
        this.showChapters = true;
      }
    }
  }
  ,





  created() {
    console.log("created in ShowVidbyId.vue", this.videoid);
    amplitude.track('VideoShareViewed', { adid: this.videoid });

  },
  mounted() {
    const queryParams = this.$route.query;
    console.log('Query Parameters:', queryParams);
    console.log('Query Parameters 2:', queryParams, queryParams.mode);
    console.log("queryParams.mode", queryParams.mode, queryParams.mode == "publish")
    if ("mode" in queryParams && queryParams.mode == "publish") {
      console.log("publish video id is ", this.videoid)
      var shareurl = "https://www.fictic.ai/vidid/" + this.videoid
      this.warnMessage = "Share link " + shareurl + " copied to clipboard"
      setTimeout(() => {
        this.warnMessage = "";
      }, 3000);

    }
    if (queryParams.edit) {
      console.log("video id is ", this.videoid)
      this.editVideoId = this.videoid
      this.showVideoEditor = true;
    }



    this.getSceneData();
    // const videoPlayer = this.$refs.videoplayer;

    // videoPlayer.addEventListener('loadeddata', () => {
    // //   videoPlayer.play();
    //   if (videoPlayer.requestFullscreen) {
    //     videoPlayer.requestFullscreen();
    //   } else if (videoPlayer.mozRequestFullScreen) { /* Firefox */
    //     videoPlayer.mozRequestFullScreen();
    //   } else if (videoPlayer.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
    //     videoPlayer.webkitRequestFullscreen();
    //   } else if (videoPlayer.msRequestFullscreen) { /* IE/Edge */
    //     videoPlayer.msRequestFullscreen();
    //   }
    // });
    this.$nextTick(() => {
      //   const videoItem = this.$refs.videoplayer;
      window.addEventListener('keydown', this.handleKeydown);


      //   if (videoItem !== undefined) {

      //         videoItem.setAttribute('tabindex', '0'); // Make div focusable
      //         videoItem.addEventListener('keydown', this.handleKeydown);

      //   }

    });

    this.$refs.videoplayer.addEventListener('loadeddata', () => {
      console.log("Video fully loaded");
      // Simulate AI button click after video is fully loaded
      if (this.$refs.aibtn) {
        this.$refs.aibtn.click();
      }
    });

  },

  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);


  }



}


</script>
<style scoped>
.videoplayer {
  width: 100%;
  -webkit-touch-callout: none;
  justify-content: center;
  border-radius: 10px;
  border: 0px solid rgb(180, 165, 165);
  /* Set the border color and width */
  box-shadow: 0 0 10px 10px rgba(87, 85, 85, 0.5);
  /* Create the fading effect */




}

.rainbow-spin {
  animation: rainbow-spin 5s linear infinite;
}

@keyframes rainbow-spin {
  0% {
    box-shadow: 0 0 10px 10px red;
  }

  16% {
    box-shadow: 0 0 10px 10px orange;
  }

  33% {
    box-shadow: 0 0 10px 10px yellow;
  }

  50% {
    box-shadow: 0 0 10px 10px green;
  }

  66% {
    box-shadow: 0 0 10px 10px blue;
  }

  83% {
    box-shadow: 0 0 10px 10px indigo;
  }

  100% {
    box-shadow: 0 0 10px 10px violet;
  }
}

.adfooter {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.imageoverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* Match the width of the video player */
  height: 87%;
  border-radius: 10px;
  /* Match the height of the video player */
  object-fit: cover;
  /* Ensure the image covers the video player area */
  z-index: 1;
  opacity: 0;
  /* Initially hidden */
  transition: opacity 2s ease;
  /* Transition effect */
  filter: pixelate(10px);
  /* Initial pixelated state */
  pointer-events: none;
}

.imageoverlay.visible {
  opacity: 1;
  /* Visible state */
  animation: pixelateIn 3s ease forwards;
  /* Apply pixelated animation */
}

.invitetext {
  color: beige;
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 10px;
}

.imageoverlay.hidden {
  opacity: 0;
  /* Visible state */
  animation: pixelateOut 3s ease forwards;
  /* Apply pixelated animation */
}


@keyframes pixelateIn {
  0% {
    filter: pixelate(10px);
  }

  30% {
    filter: pixelate(7px);
  }

  60% {
    filter: pixelate(4px);
  }

  100% {
    filter: pixelate(0px);
  }
}

@keyframes pixelateOut {
  0% {
    filter: pixelate(0px);
  }

  30% {
    filter: pixelate(3px);
  }

  60% {
    filter: pixelate(7px);
  }

  100% {
    filter: pixelate(10px);
  }
}


.askbutton {
  margin-top: 10px;
  width: auto;
  height: auto;
  cursor: pointer;
  border-radius: 10px;
  margin-left: 10px;

}
.searchgroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  /* width: 40%; */
  margin-bottom: 10px;
  width:100%;
  align-items: center;
}

.queryinput{
  margin-top:5px;
  width: 90%;
  border-radius: 20px;
}
.searchbtn{
  margin-top:5px;
  margin-left: 10px;
  border-radius: 20px;
  background: #E76048;
  border:#E76048
}

.logo {
  width: 50px;
  height: auto;
  cursor: pointer
}

.actionspanel {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
  /* width: 40%; */
  margin-bottom: 30px;
  border: 10px;
  background-color: rgba(30, 30, 27, 0.817);
  border-radius: 30px;
  width: 20vw;
  align-items: center;
}



.queryop {
  width: 60vw;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
  height: 70%;
  /* Adjust as needed */
}


.video-container {
  position: relative;
  max-width: 80%;
  max-height: 80%;
  margin: 0 auto;
}

.nonquerybutton {
  width: 50px;
  height: auto;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}

.querybutton {
  width: 75px;
  height: auto;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}

.querybutton:hover {
  transform: scale(1.2);
}

.nonquerybutton:hover {
  transform: scale(1.1);
}

.poweredby {
  margin-top: 14px;
  color: beige;
}

@media screen and (max-width: 1000px) {
  .videoplayer {
    max-width: 70%;
    height: 100vh;
    -webkit-touch-callout: none;
    justify-content: center;
  }

}

@media screen and (max-width: 768px) {
  .videoplayer {
    max-width: 80%;
    height: 100vh;
    -webkit-touch-callout: none;
    justify-content: center;
  }

}

.chapterList {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  margin-top: 10px;
}

.embedbtn{
  width: 30px;
  height: auto;
  cursor: pointer;
}

.chapter {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background-color: rgb(34, 34, 32);
  margin-top: 10px;
  cursor: pointer;

}

.chapter :hover {
  background-color: rgb(83, 83, 77);


}
</style>